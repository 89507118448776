<template>
    <section class="main">
        <h1>{{ $route.name }}</h1>
        <div class="grid-container mb-4 mw-m">
            <div class="row justify-between">
                <div class="col text center">
                    <p>Here is a little playground to fiddle around!</p>
                    <h3>Latest cat fact:</h3>
                    <p>{{ latestCatFact }}</p>
                    <button type="button" @click="getNewCatFact">New cat fact</button>
                </div>
            </div>
        </div>
        <div class="grid-container mb-4">
          <div class="row justify-between">
            <div class="col text center">
              <h3>Random Pokémon:</h3>
              <div class="poke-img-wrapper" :class="{ loading: loadingPoke}">
                <img :src="randomPoke" /><br />
              </div>
              <button type="button" @click="getNewPoke">New Pokémon</button>
            </div>
          </div>
        </div>
        <div class="grid-container" v-if="0">
          <div class="row justify-between">
            <div class="col text center">
            </div>
          </div>
        </div>
    </section>
</template>

<style scoped>
.poke-img-wrapper {
  margin: 0 auto;
  width: 96px; height: 96px;
}
.poke-img-wrapper.loading {
    background-image: url("../assets/img/icon/loading.svg"); background-size: 100%;
}
</style>

<script>
import { defineComponent } from 'vue'
import axios from 'axios'
// import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src

export default defineComponent({
  name: 'Shenans',
  data () {
    return {
        latestCatFact: '',
        randomPoke: '',
        loadingPoke: false,
        newTodoItem: ''
    }
  },
  methods: {
      getNewCatFact: function(){
          axios.get('https://cat-fact.herokuapp.com/facts/random').then(response => (this.latestCatFact = response.data.text))
      },
      getNewPoke: function(){
        this.randomPoke = ''
        this.loadingPoke = true
        let randomPokeNumber = Math.floor(Math.random() * (898 - 1 + 1)) + 1
        let pokeurl = 'https://pokeapi.co/api/v2/pokemon/' + randomPokeNumber
        axios.get(pokeurl).then(response => { this.loadingPoke = false; this.randomPoke = response.data.sprites.front_default})
      }
  },
  mounted() {
    axios.get('https://cat-fact.herokuapp.com/facts/random').then(response => (this.latestCatFact = response.data.text))
    this.loadingPoke = true
    let randomPokeNumber = Math.floor(Math.random() * (898 - 1 + 1)) + 1
    let pokeurl = 'https://pokeapi.co/api/v2/pokemon/' + randomPokeNumber
    axios.get(pokeurl).then(response => { this.loadingPoke = false; this.randomPoke = response.data.sprites.front_default})
  }
//   components: {
//     HelloWorld,
//   },
});
</script>